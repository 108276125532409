import React from 'react';
import Navbar from './Navbar.js';
import SScreenNavbar from './S-Screen-Navbar.js';
import Footer from './Footer.js';
import '../css/Sponsoren.css'
import SpImgSection4 from '../assets/images/Jojo_img.jpg'
import SpImgSection5 from '../assets/images/teamfoto_img.jpeg'
function Sponsoren() {
    return(
        <div>
            <SScreenNavbar />
            <div className='container'>
                <div className='sp-section1'>
                    <Navbar backgroundColor='#008cf0' />
                    <div className='sp-section1-h'>
                        <h1 className='h1-blue'>
                            <span className='h1-black'>FÜR SPONSOREN - </span>
                            GEMEINSAM MEHR ERREICHEN
                        </h1>
                    </div>
                    <div className='sp-section1-sub'>
                        <h4>Ihre Marke. Unser Team. Gemeinsame Erfolge</h4>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='sp-section2'>
                    <div className='hp-section2-h'>
                        <div className='bluebar' />
                        <h2>IHRE VORTEILE ALS SPONSOR:</h2>
                    </div>
                    <div className='sp-section2-text'>
                        <h4>
                            <span className='h4-blue'> 1. Direkte Zielgruppenansprache: </span>
                            Platzieren Sie ihr Logo auf unseren Karten und erreichen Sie sportbegeisterte Familien in Ihrer Region.
                        </h4>
                        <h4>
                            <span className='h4-blue'> 2. Emotionaler Mehrwert: </span>
                            Werden Sie Teil der besonderen Momente, die die Teilnehmer und ihre Familien mit unseren Karten erleben.
                        </h4>
                        <h4>
                            <span className='h4-blue'> 3. Langfristige Präsenz: </span>
                            Unsere Karten sind nicht nur für den Moment - sie werden gesammelt, getauscht und bleiben am Kühlschrank der Familien in Erinnerung.
                        </h4>
                        <h4>
                            <span className='h4-blue'> 4. Positive Wahrnehmung: </span>
                            Unterstützen Sie Camps und Kinder in ihrer sportlichen Entwicklung und präsentieren Sie sich als Partner des regionalen Jugendsports. 
                        </h4>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='hp-section5'>
                    <div className='sp-section3-h'>
                        <h2>WIE ES FUNKTIONIERT</h2>
                    </div>
                    <div className='sp-section3-b1'>
                        <h4 className='h4-blue'>Logo-Integration:</h4>
                        <h4>Ihr Logo wird prominent auf 3 Plätzen plaziert. Vorder- und Rückseite, sowie Verpackung.</h4>
                    </div>
                    <div className='sp-section3-b2'>
                        <h4 className='h4-blue'>Flexible Optionen:</h4>
                        <h4>Wählen Sie zwischen den Platzierungen oder nutzen Sie freie Flächen auf der Rückseite, für QR und Rabatt Codes.</h4>
                    </div>
                    <div className='sp-section3-b3'>
                        <h4 className='h4-blue'>Individuelle Partnerschaft:</h4>
                        <h4>Profitieren Sie von einer Innovativen Werbefläche im Jugendsport und heben Ihre Marke von der Konkurrenz ab.</h4>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='sp-section4'>
                    <div className='hp-section6-2h'>
                        <div className='bluebar' />  
                        <h3>WARUM TEAM-CARDS?</h3>
                    </div>
                    <div className='hp-section6-2text'>
                        <h4>
                            Mit unserer Plattfrom erreichen Sie nicht nur eine lokale, sondern auch eine stark
                            engagierte Zielgruppe. Kinder, Eltern und Fußballfans verbinden Ihre Marke mit positiven
                            Erlebnissen und einem starken Gemeinschaftsgefühl. Ihre Unterstützung wird sichtbar - direkt
                            in den Händen derjenigen, die Sie erreichen möchten.
                        </h4>
                    </div>
                    <div className='sp-section4-img' style={{
        backgroundImage: `url(${SpImgSection4})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }} />
                </div>
            </div>
            <div className='container'>
                <div className='sp-section5-img' style={{
        backgroundImage: `url(${SpImgSection5})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}>
                    <div className='sp-section5-text'>
                        <h1 className='h1-blue'>WERDEN SIE TEIL UNSERES TEAMS!</h1>
                        <h4 className='h4-white'>
                            Gemeinsam machen wir Fußballcamps unvergesslich und schaffen wertvolle Verbindungen.
                            Lassen Sie uns über Ihre Möglichkeiten sprechen und ein individuelles Sponsoring-Paket für Sie schnüren.
                            <br/>
                            Kontaktieren Sie uns jetzt und wir zeigen Ihnen, wie Ihre Marke ein Teil von der Team-Cards Bewegung werden kann!
                        </h4>
                    </div> 

                </div>
            </div>
            <Footer />
        </div>
    );
}
export default Sponsoren;