import React from 'react';
import { NavLink } from 'react-router-dom';
import LogoImg from '../assets/images/Logo_blueBG.JPG'
import '../css/Footer.css';

const Footer = () => {
  const handleClick = () => {
    window.scrollTo(0, 0);
  }
  return (
    <div className='container'>
      <footer className="footer">
        <div className='footer-img' style={{
        backgroundImage: `url(${LogoImg})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}/>
        <div className='footer-links-left'>
          <NavLink to="/" exact className="nav-link" onClick={handleClick}>
            Home
          </NavLink>
          {/* <NavLink to="/spieler-quiz" className="nav-link">
            Spieler-Quiz
          </NavLink> */}
          {/*<NavLink to="/foto-upload" className="nav-link">
            Foto Upload
        </NavLink>*/}
         {/* <NavLink to="/organisatoren" className="nav-link" onClick={handleClick}>
            Für Organisatoren
          </NavLink> */}
          <NavLink to="/sponsoren" className="nav-link" onClick={handleClick}> 
            Für Sponsoren
          </NavLink>
        </div>
        <div className='footer-links-right'>
          <NavLink to="/über-uns" className="nav-link" onClick={handleClick}>
            Über Uns
          </NavLink>
          <NavLink to="/impressum" className="nav-link" onClick={handleClick}>
            Impressum
          </NavLink>
          <NavLink to="/datenschutzerklärung" className="nav-link" onClick={handleClick}>
            Datenschutzerklärung
          </NavLink>
        </div>
      </footer>
    </div>
  );
};
export default Footer;
