import React, { useRef, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import LogoImg from "../assets/images/TC_Logo.png";
import '../css/Navbar.css';

function SScreenNavbar() {
  const navRef = useRef();
  const wrapperRef = useRef();
  const [isNavActive, setIsNavActive] = useState(false);

  const showNavbar = () => {
    navRef.current.classList.toggle("s_responsive_nav");
    setIsNavActive((prev) => !prev); // Toggle the state
  };

  const handleClickOutside = (event) => {
    if (navRef.current && !navRef.current.contains(event.target) &&
        wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      navRef.current.classList.remove("s_responsive_nav");
      setIsNavActive(false); // Reset the state when clicking outside
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`nav-wrapper ${isNavActive ? "nav-wrapper-active" : ""}`}
      ref={wrapperRef}
    >
      <div className="navbtn-wrapper">
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </div>
      <nav ref={navRef} className="s_navbar">
        <NavLink>
          <img src={LogoImg} alt="Team-Cards.de" />
        </NavLink>
        <NavLink to="/" className="nav-link">
          <h4 className='h4-white'>Home</h4>
        </NavLink>
        <NavLink to="/organisatoren" className="nav-link">
          <h4 className='h4-white'>Für Organisatoren</h4>
        </NavLink>
        <NavLink to="/sponsoren" className="nav-link">
          <h4 className='h4-white'>Für Sponsoren</h4>
        </NavLink>
        <NavLink to="/über-uns" className="nav-link">
          <h4 className='h4-white'>Über Uns</h4>
        </NavLink>
      </nav>
    </div>
  );
}

export default SScreenNavbar;
